import { io } from "socket.io-client";

const socket = io("https://ludo-server-skill.herokuapp.com", {
  transports: ["websocket"],
  rejectUnauthorized: false,
  reconnection: false,
});
socket.on("connect", () => {
  console.log("connected");
});
socket.on("disconnect", () => {
  console.log("Disconnected");
});

export { socket };
