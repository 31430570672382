/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { socket } from "../../config/socket";
import stepSound from "../../assets/audio/step.mp3";
import deadSound from "../../assets/audio/dead.mp3";
import inputSound from "../../assets/audio/inout.mp3";
import diceSound from "../../assets/audio/dice.mp3";
import winnerSound from "../../assets/audio/winner.mp3";
import timerRunningOut from "../../assets/audio/timer_running_out.mp3";
import chaticon from "../../assets/img/chat1.png";
import user from "../../assets/img/user.png";
import logo from "../../assets/img/logo.png";
import emoji1 from "../../assets/img/emoji/1.gif";
import emoji2 from "../../assets/img/emoji/2.gif";
import emoji3 from "../../assets/img/emoji/3.gif";
import emoji4 from "../../assets/img/emoji/4.gif";
import emoji6 from "../../assets/img/emoji/6.gif";
import emoji7 from "../../assets/img/emoji/7.gif";
import emoji8 from "../../assets/img/emoji/8.gif";
import emoji9 from "../../assets/img/emoji/9.gif";
import emoji10 from "../../assets/img/emoji/10.gif";
import emoji11 from "../../assets/img/emoji/11.gif";
import rank1 from "../../assets/img/gold.png";
import rank2 from "../../assets/img/silver.png";
import rank3 from "../../assets/img/bronze.png";
import staricon from "../../assets/img/win-star.png";
import winOk from "../../assets/img/win-ok.png";
import winReset from "../../assets/img/win-reset.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BubbleMessage from "./bubbleMessage";

let userId;
let tableId;
let gameCollection;
let tPlayer;
let room;

const Home = () => {
  const [currentPlayer, setCurrentPlayer] = useState();
  const [diceDisabled, setDiceDisabled] = useState(false);
  const [gameLeftTime, setGameLeftTime] = useState("");
  const [timer, setTimer] = useState();
  const [leftTime, setLeftTime] = useState(0);
  const [isEmergencyTimer, setIsEmergencyTimer] = useState(false);
  const [roomData, setRoomData] = useState();
  const [chat, setChat] = useState(false);
  const [isGameFinished, setIsGameFinished] = useState(false);
  const [winners, setWinners] = useState([]);

  const handleChat = () => {
    setChat(!chat);
  };

  const handleSendMessage = (message) => {
    socket.emit("chatMessage", {
      userId,
      message,
      tableId,
    });
  };

  useEffect(() => {
    const isLoggedIn = async () => {
      let urlParams = new URLSearchParams(window.location.search);
      // let user;

      if (urlParams.get("token")) {
        tableId = urlParams.get("tableid");
        gameCollection =
          urlParams.get("gameCollection") || urlParams.get("gamecollection");
        socket.emit("checkTable", {
          tableId,
          userId: null,
          gameType: gameCollection,
          token: urlParams.get("token"),
        });
      } else {
        if (localStorage.getItem("userId")) {
          userId = localStorage.getItem("userId");
        } else {
          userId = Math.floor(Math.random() * (1000000 - 1000 + 1) + 1000);
          localStorage.setItem("userId", userId);
        }
        tableId = urlParams.get("tableid");
        gameCollection =
          urlParams.get("gameCollection") || urlParams.get("gamecollection");
        socket.emit("checkRoom", {
          tableId,
          userId,
          gameType: gameCollection,
        });
      }
    };
    isLoggedIn();
  }, []);

  useEffect(() => {
    const tryReconnect = () => {
      setTimeout(() => {
        console.log("reconnect");
        socket.io.open((err) => {
          if (err) {
            console.log("reconnect err => ", err);
            tryReconnect();
          } else {
            // re join
            let urlParams = new URLSearchParams(window.location.search);
            tableId = urlParams.get("tableid");
            let gameCollection =
              urlParams.get("gameCollection") ||
              urlParams.get("gamecollection");
            socket.emit("checkRoom", {
              userId,
              tableId,
              gameType: gameCollection,
            });
          }
        });
      }, 2000);
    };
    socket.io.on("close", tryReconnect);
  }, []);

  const handleSoundPlay = (ele) => {
    let element = document.getElementById(ele);
    if (element) element.play();
  };

  const handleSoundStop = (ele) => {
    let element = document.getElementById(ele);
    if (element) element.stop();
  };

  const handleDiceRoll = async () => {
    socket.emit("rollDice", { tableId, userId, gameCollection });
  };

  const handleStartGame = () => {
    socket.emit("startGame", { userId, tableId, gameCollection });
  };

  const handleMovePawn = (selectedPawn) => {
    console.log("pawn =>", selectedPawn.color, room.players);
    if (
      tPlayer === userId &&
      selectedPawn.color ===
        room?.players.find((player) => player.id === userId)?.base
    )
      socket.emit("movePawn", {
        tableId,
        userId,
        gameCollection,
        selectedPawn,
        dice: roomData?.dice,
      });
  };

  const addListnersToPawn = async (data) => {
    let pawns = [];
    Object.values(data.bases).forEach((el) => {
      pawns = [...pawns, ...el];
    });
    const availablePawns = pawns.filter((pawn) =>
      data.players.find((pl) => pl.base === pawn.color)
    );
    for await (const pawn of availablePawns) {
      let id = `${pawn.color}-player-${pawn.coinId}`;
      const currentPawn = document.getElementById(id);
      if (currentPawn)
        currentPawn.addEventListener("click", () => handleMovePawn(pawn));
    }
  };

  const renderPawns = async (data) => {
    let pawns = [];
    Object.values(data.bases).forEach((el) => {
      pawns = [...pawns, ...el];
    });
    console.log("pawns =>", pawns);
    const homePawns = pawns.filter(
      (pawn) =>
        pawn.status === "home" &&
        data.players.find((pl) => pl.base === pawn.color)
    );
    ["red", "yellow", "blue", "green"].forEach((ele) => {
      let item = document.getElementById(`${ele}-home`);
      item.innerHTML = "";
    });
    for await (const pawn of homePawns) {
      const parent = document.getElementById(`${pawn.color}-home`);
      parent.innerHTML += `<div class="player bg-${pawn.color} move${pawn.color}${pawn.coinId}" id="${pawn.color}-player-${pawn.coinId}"></div>`;
    }
    const activePawns = pawns.filter((pawn) => pawn.status === "active");
    const paths = document.getElementsByClassName("step");
    for await (const path of paths) {
      path.innerHTML = "";
    }
    for await (const pawn of activePawns) {
      let ele = paths[pawn.current_step];
      if (ele) {
        //let id = `${pawn.color}-player-${pawn.coinId}`
        ele.innerHTML += `<div class="player bg-${pawn.color} move${
          pawn.color
        }${pawn.coinId} ${
          pawn.color ===
          data.players.find((pl) => pl.id === data.timerPlayer)?.base
            ? "active"
            : ""
        }" id="${pawn.color}-player-${pawn.coinId}"></div>`;

        //const active = document.getElementById(id)
        //if(active)
        //active.addEventListener('click', () => handleMovePawn(pawn))
      }
    }
    await addListnersToPawn(data);
  };

  useEffect(() => {
    socket.on("updateRoom", (data) => {
      setRoomData(data);
      room = data;
      //  let current = data.players.find(
      //    (player) => player.id === data.timerPlayer
      //  );
      //  setCurrentPlayer(current);

      renderPawns(data);
      if (data.finish) {
        setWinners(data.handWinner);
        setIsGameFinished(true);
      }
    });

    socket.on("rolledDice", (data) => {
      setRoomData(data);
      room = data;
      let dice = document.getElementById("dice");
      dice.dataset.side = data.dice;
      dice.classList.toggle("reRoll");
      handleSoundPlay("diceSound");
    });

    socket.on("autoMovePawn", (data) => {
      document.getElementById(`${data.color}-player-${data.coinId}`).click();
    });

    socket.on("movePawn", (data) => {
      let paths = document.getElementsByClassName("step");
      let previous = paths[data.previous_step];
      let currnetPawn;
      currnetPawn = `<div class="player bg-${data.color} move${data.color}${data.coinId} active" id="${data.color}-player-${data.coinId}"></div>`;

      if (previous) {
        console.log("cccc =>", currnetPawn);
        previous.innerHTML = previous.innerHTML.replace(currnetPawn, "");
      }
      if (data.current_step !== "win") {
        let current = paths[data.current_step];
        current.innerHTML = current.innerHTML + currnetPawn;
      }
      handleSoundPlay("stepSound");
    });

    socket.on("enemyKill", (data) => {
      let paths = document.getElementsByClassName("step");
      let previous = paths[data.previous_step];
      if (previous) {
        previous.innerHTML = previous.innerHTML.replace(data.player, "");
      }
      handleSoundPlay("deadSound");
    });
    socket.on("pawnWin", (data) => {
      setRoomData(data);
      room = data;
      handleSoundPlay("winnerSound");
      renderPawns(data);
      if (data.lastRank >= data.players.length - 1) {
        setIsGameFinished(true);
      } else {
        toast(() => <span>Extra Move, Re-roll Dice</span>, {
          id: "extra move",
        });
      }
    });
    socket.on("extraMove", () => {
      toast(() => <span>Extra Move, Re-roll Dice</span>, { id: "extra move" });
    });
    socket.on("actionError", (data) => {
      toast(() => <span>{data.msg}</span>, { id: "error" });
    });

    socket.on("gameTimeComplete", () => {
      toast(() => <span> Game time is completed,</span>);
    });
  }, []);

  useEffect(() => {
    socket.on("gameTimer", (data) => {
      if (tPlayer !== data.id && room.lastRank <= room.players.length - 1) {
        if (room.players.find((ele) => ele.id === data.id)) {
          tPlayer = data.id;
          setCurrentPlayer(() => {
            return room.players.find((ele) => ele.id === data.id);
          });
        }
      }

      setIsEmergencyTimer(false);
      setTimer(data.time);
      setLeftTime(data.leftTime);
    });
    socket.on("emergencyTimer", (data) => {
      if (tPlayer !== data.id && room.lastRank <= room.players.length - 1) {
        if (room.players.find((ele) => ele.id === data.id)) {
          tPlayer = data.id;
          setCurrentPlayer(() => {
            return room.players.find((ele) => ele.id === data.id);
          });
        }
      }

      setIsEmergencyTimer(true);
      setTimer(data.time);
      setLeftTime(data.leftTime);
      if (data.leftTime === 5) {
        handleSoundPlay("timerRunningOut");
      } else if (data.leftTime === 0) {
        handleSoundStop("timerRunningOut");
      }
    });

    socket.on("gameTimeLimit", (data) => {
      let value = data.leftTime;
      let mm =
        Math.floor(value / 60) < 10
          ? `0${Math.floor(value / 60)}`
          : Math.floor(value / 60);
      let ss = value % 60 < 10 ? `0${value % 60}` : value % 60;
      setGameLeftTime(mm + ":" + ss);
    });
  }, []);

  return (
    <div className={`ludo-game ${isGameFinished ? `winner-overlay` : ``}`}>
      <div className="logo-lv">
        <img src={logo} alt="" />
      </div>

      {roomData?.gameType !== "Ludo_Classic_Table" ? (
        <div className="game-timer">{gameLeftTime}</div>
      ) : (
        ""
      )}
      <div className="ludo-board">
        <div className="red-home red-home-bg bg-red super-center">
          <div
            className={`white-box super-center ${
              currentPlayer?.base === "red" ? "current-player" : ""
            }${
              roomData &&
              roomData.players.find((player) => player.base === "red")?.rank
                ? `winner-rank-${
                    roomData &&
                    roomData.players.find((player) => player.base === "red")
                      ?.rank
                  }`
                : ""
            }`}
          >
            <PlayerAvatar
              currentPlayer={currentPlayer}
              player={roomData?.players?.find(
                (player) => player.base === "red"
              )}
              isEmergencyTimer={isEmergencyTimer}
              timer={timer}
              leftTime={leftTime}
              placement="right"
            />
            <div className="player-room" id="red-home"></div>
          </div>
        </div>
        <div className="step"></div>
        <div className="step green-arrow"></div>
        <div className="step"></div>
        <div className="green-home green-home-bg bg-green super-center">
          <div
            className={`white-box super-center ${
              currentPlayer?.base === "green" ? "current-player" : ""
            } ${
              roomData &&
              roomData.players.find((player) => player.base === "green")?.rank
                ? `winner-rank-${
                    roomData &&
                    roomData.players.find((player) => player.base === "green")
                      ?.rank
                  }`
                : ""
            }`}
          >
            <PlayerAvatar
              currentPlayer={currentPlayer}
              player={roomData?.players?.find(
                (player) => player.base === "green"
              )}
              isEmergencyTimer={isEmergencyTimer}
              timer={timer}
              leftTime={leftTime}
              placement="left"
            />
            <div className="player-room" id="green-home"></div>
          </div>
        </div>
        <div className="step"></div>
        <div className="step bg-green"></div>
        <div className="step bg-green green-stop-home"></div>
        <div className="step red-stop"></div>
        <div className="step bg-green"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step bg-green"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step bg-green"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step bg-green"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step bg-red red-stop-home"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="winner-home">
          <div className="rwh stack">
            {roomData &&
            roomData.players.find((player) => player.base === "red")
              ? roomData.bases["red"]
                  .filter((coin) => coin.status === "win")
                  .map((coin) => (
                    <div
                      key={coin.coinId}
                      className={`player bg-red movered${coin.coinId}`}
                      id={`red-player-${coin.coinId}`}
                    ></div>
                  ))
              : ""}
          </div>
          <div className="gwh stack">
            {roomData &&
            roomData.players.find((player) => player.base === "green")
              ? roomData.bases["green"]
                  .filter((coin) => coin.status === "win")
                  .map((coin) => (
                    <div
                      key={coin.coinId}
                      className={`player bg-green movegreen${coin.coinId}`}
                      id={`green-player-${coin.coinId}`}
                    ></div>
                  ))
              : ""}
          </div>
          <div className="bwh stack">
            {roomData &&
            roomData.players.find((player) => player.base === "blue") &&
            roomData.bases.blue
              ? roomData.bases["blue"]
                  .filter((coin) => coin.status === "win")
                  .map((coin) => (
                    <div
                      key={coin.coinId}
                      className={`player bg-blue moveblue${coin.coinId}`}
                      id={`blue-player-${coin.coinId}`}
                    ></div>
                  ))
              : ""}
          </div>
          <div className="ywh stack">
            {roomData &&
            roomData.players.find((player) => player.base === "yellow") &&
            roomData.bases.yellow
              ? roomData.bases["yellow"]
                  .filter((coin) => coin.status === "win")
                  .map((coin) => (
                    <div
                      key={coin.coinId}
                      className={`player bg-yellow moveyellow${coin.coinId}`}
                      id={`yellow-player-${coin.coinId}`}
                    ></div>
                  ))
              : ""}
          </div>
        </div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step green-stop"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step red-arrow"></div>
        <div className="step bg-red"></div>
        <div className="step bg-red"></div>
        <div className="step bg-red"></div>
        <div className="step bg-red"></div>
        <div className="step bg-red"></div>
        <div className="step bg-yellow"></div>
        <div className="step bg-yellow"></div>
        <div className="step bg-yellow"></div>
        <div className="step bg-yellow"></div>
        <div className="step bg-yellow"></div>
        <div className="step yellow-arrow"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step blue-stop"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step bg-yellow yellow-stop-home"></div>
        <div className="step"></div>
        <div className="blue-home bg-blue blue-home-bg super-center">
          <div
            className={`white-box super-center ${
              currentPlayer?.base === "blue" ? "current-player" : ""
            }${
              roomData &&
              roomData.players.find((player) => player.base === "blue")?.rank
                ? `winner-rank-${
                    roomData &&
                    roomData.players.find((player) => player.base === "blue")
                      ?.rank
                  }`
                : ""
            }`}
          >
            <PlayerAvatar
              currentPlayer={currentPlayer}
              player={roomData?.players?.find(
                (player) => player.base === "blue"
              )}
              isEmergencyTimer={isEmergencyTimer}
              timer={timer}
              leftTime={leftTime}
              placement="right"
            />
            <div className="player-room" id="blue-home"></div>
          </div>
        </div>
        <div className="step"></div>
        <div className="step bg-blue"></div>
        <div className="step"></div>
        <div className="yellow-home yellow-home-bg bg-yellow super-center">
          <div
            className={`white-box super-center ${
              currentPlayer?.base === "yellow" ? "current-player" : ""
            }`}
          >
            <PlayerAvatar
              currentPlayer={currentPlayer}
              player={roomData?.players?.find(
                (player) => player.base === "yellow"
              )}
              isEmergencyTimer={isEmergencyTimer}
              timer={timer}
              leftTime={leftTime}
              placement="left"
            />
            <div className="player-room" id="yellow-home"></div>
          </div>
        </div>
        <div className="step"></div>
        <div className="step bg-blue"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step bg-blue"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step bg-blue"></div>
        <div className="step yellow-stop"></div>
        <div className="step bg-blue blue-stop-home"></div>
        <div className="step bg-blue"></div>
        <div className="step"></div>
        <div className="step"></div>
        <div className="step blue-arrow"></div>
        <div className="step"></div>
      </div>

      <div className="test_controller">
        {!roomData?.gamestart && roomData?.hostId === userId ? (
          <div className="start-game">
            <button onClick={handleStartGame}> Start Game</button>
          </div>
        ) : roomData?.gamestart ? (
          <div className="die-btn">
            <div className="dice-box">
              <div
                className={`dice${roomData?.dice} ${
                  diceDisabled ||
                  currentPlayer?.id !== userId ||
                  roomData.dice !== 0
                    ? "dice-disabled"
                    : ""
                } ${currentPlayer?.base === "red" ? "red-dice" : ""} ${
                  currentPlayer?.base === "green" ? "green-dice" : ""
                } ${currentPlayer?.base === "blue" ? "blue-dice" : ""} ${
                  currentPlayer?.base === "yellow" ? "yellow-dice" : ""
                }`}
                onClick={handleDiceRoll}
              >
                <div id="dice" data-side="1">
                  <div className="sides side-1">
                    <span className="dot dot-1"></span>
                  </div>
                  <div className="sides side-2">
                    <span className="dot dot-1"></span>
                    <span className="dot dot-2"></span>
                  </div>
                  <div className="sides side-3">
                    <span className="dot dot-1"></span>
                    <span className="dot dot-2"></span>
                    <span className="dot dot-3"></span>
                  </div>
                  <div className="sides side-4">
                    <span className="dot dot-1"></span>
                    <span className="dot dot-2"></span>
                    <span className="dot dot-3"></span>
                    <span className="dot dot-4"></span>
                  </div>
                  <div className="sides side-5">
                    <span className="dot dot-1"></span>
                    <span className="dot dot-2"></span>
                    <span className="dot dot-3"></span>
                    <span className="dot dot-4"></span>
                    <span className="dot dot-5"></span>
                  </div>
                  <div className="sides side-6">
                    <span className="dot dot-1"></span>
                    <span className="dot dot-2"></span>
                    <span className="dot dot-3"></span>
                    <span className="dot dot-4"></span>
                    <span className="dot dot-5"></span>
                    <span className="dot dot-6"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {currentPlayer?.id === userId && roomData?.gamestart ? (
          <>
            <div
              className={`${currentPlayer?.base}control control current-player-control`}
            ></div>
          </>
        ) : (
          ""
        )}
      </div>

      {/* Audio Sounds */}

      <audio src={diceSound} id="diceSound" />
      <audio src={stepSound} id="stepSound" />
      <audio src={deadSound} id="deadSound" />
      <audio src={inputSound} id="inputSound" />
      <audio src={winnerSound} id="winnerSound" />
      <audio id="timerRunningOut" src={timerRunningOut} />

      <div className="chat-icon" onClick={handleChat}>
        <img src={chaticon} alt="" />
      </div>

      {chat && (
        <Chat
          chat={chat}
          handleChat={handleChat}
          handleSendMessage={handleSendMessage}
          setChat={setChat}
        />
      )}

      {isGameFinished && (
        <div className="winner-popup">
          <div className="winner-popup-content">
            <div className="win-star">
              <img src={staricon} alt="" />
            </div>
            <div className="win-players">
              <ul>
                {winners.map((winner) => (
                  <li key={`item-${winner.id}`}>
                    <div className="win-player-user-grid">
                      <div className="win-player-user">
                        <img src={winner.avatar} alt="" />
                        <h4>{winner.name}</h4>
                      </div>
                      <div className="win-player-content">{winner.price}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="win-btns">
              <button>
                <img src={winOk} alt="" />
              </button>
              <button>
                <img src={winReset} alt="" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;

const PlayerAvatar = ({
  currentPlayer,
  player,
  isEmergencyTimer,
  timer,
  leftTime,
  placement,
}) => {
  const [percent, setPercent] = useState();
  const [message, setMessage] = useState("");
  const [messageBy, setMessageBy] = useState();
  const target = useRef();
  const [showFollowMe, setShowFollowMe] = useState(false);
  const { stats: { countryCode } = {} } = player || {};
  useEffect(() => {
    if (leftTime && timer) {
      let percent = (leftTime / timer) * 100;
      setPercent(parseInt(percent));
    }
  }, [leftTime, timer]);

  useEffect(() => {
    socket.on("newMessage", (data) => {
      setMessage(data.message);
      setMessageBy(data.userId);
      setTimeout(() => {
        setMessage("");
        setMessageBy("");
      }, 1000);
    });
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="player-stats">
        <div className="player-stats-user">
          <img src={player?.avatar} alt="player" />
          <div className="player-stats-user-info">
            <h4>{player?.name}</h4>

            <img
              src={
                countryCode
                  ? `https://countryflagsapi.com/png/${countryCode}`
                  : "https://countryflagsapi.com/png/us"
              }
              alt="country"
            />
          </div>
        </div>

        <ul>
          <li>
            Matches: <span>5214</span>
          </li>
          <li>
            Won: <span>3200</span>
          </li>
          <li>
            Lose: <span>2014</span>
          </li>
          <li>
            Level: <span>3</span>
          </li>
        </ul>
      </div>
    </Tooltip>
  );

  return (
    <div
      className={`player-avatar-grid ${player?.rank ? `winner-player` : ""}`}
    >
      {player?.rank ? (
        <>
          <div className="after"></div>
          <div className="before"></div>
          <div className="position-image">
            <img
              src={
                player?.rank === 1 ? rank1 : player?.rank === 2 ? rank2 : rank3
              }
              alt="position-icon"
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {!player ? (
        <div className="player-avatar">
          <img src={user} alt="sdsd" />
        </div>
      ) : (
        <>
          {/*<OverlayTrigger
        placement={placement}
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >*/}
          <div
            className="player-avatar"
            onClick={() => {
              if (player?.id !== userId) {
                setShowFollowMe(!showFollowMe);
              }
            }}
            ref={target}
          >
            <img src={player?.avatar} alt="" />
            {currentPlayer?.base === player?.base && (
              <div
                className={`ludo-timer ${
                  isEmergencyTimer ? "emergency-timer" : ""
                }`}
              >
                <CircularProgressbar
                  counterClockwise
                  value={percent}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                  })}
                />
              </div>
            )}
          </div>
          {player ? (
            <span className="user-ludo-score">Score - {player?.score}</span>
          ) : (
            ""
          )}
          {/*</OverlayTrigger>*/}
          <Overlay
            target={target.current}
            show={showFollowMe}
            placement="right"
            rootClose={true}
            onHide={() => setShowFollowMe(false)}
          >
            {({ placement, arrowProps, show: _show, popper, ...props }) =>
              renderTooltip(props)
            }
          </Overlay>
          {player?.id === messageBy && player ? (
            <BubbleMessage message={message} />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

const Chat = ({ chat, setChat, handleChat, handleSendMessage }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setChat(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <div className="chat-box-wrapper" ref={wrapperRef}>
      <div className="chat-box-header">
        <h6> Quick Chat</h6>
        <span role="presentation" chat={chat} onClick={handleChat}>
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div className="chat-box-body">
        <div className="chat-emoji-grid">
          <img
            src={emoji1}
            alt="icon"
            onClick={() => handleSendMessage("emoji1")}
          />
          <img
            src={emoji2}
            alt="icon"
            onClick={() => handleSendMessage("emoji2")}
          />
          <img
            src={emoji3}
            alt="icon"
            onClick={() => handleSendMessage("emoji3")}
          />
          <img
            src={emoji4}
            alt="icon"
            onClick={() => handleSendMessage("emoji4")}
          />
          <img
            src={emoji6}
            alt="icon"
            onClick={() => handleSendMessage("emoji6")}
          />
          <img
            src={emoji7}
            alt="icon"
            onClick={() => handleSendMessage("emoji7")}
          />
          <img
            src={emoji8}
            alt="icon"
            onClick={() => handleSendMessage("emoji8")}
          />
          <img
            src={emoji9}
            alt="icon"
            onClick={() => handleSendMessage("emoji9")}
          />
          <img
            src={emoji10}
            alt="icon"
            onClick={() => handleSendMessage("emoji10")}
          />
          <img
            src={emoji11}
            alt="icon"
            onClick={() => handleSendMessage("emoji11")}
          />
        </div>
        <div className="chat-text-grid">
          <span onClick={() => handleSendMessage("Awesome")}>Awesome</span>{" "}
          <span onClick={() => handleSendMessage("Perfect")}>Perfect</span>{" "}
          <span onClick={() => handleSendMessage("Lovely")}>lovely</span>
          <span onClick={() => handleSendMessage("Alas!")}>Alas!</span>
          <span onClick={() => handleSendMessage("Hurrah!")}>Hurrah!</span>{" "}
          <span onClick={() => handleSendMessage("Bravo!")}>Bravo!</span>{" "}
          <span onClick={() => handleSendMessage("Alas")}>Alas!</span>
        </div>
      </div>
    </div>
  );
};
