import emoji1 from "../../assets/img/emoji/1.gif";
import emoji2 from "../../assets/img/emoji/2.gif";
import emoji3 from "../../assets/img/emoji/3.gif";
import emoji4 from "../../assets/img/emoji/4.gif";
import emoji6 from "../../assets/img/emoji/6.gif";
import emoji7 from "../../assets/img/emoji/7.gif";
import emoji8 from "../../assets/img/emoji/8.gif";
import emoji9 from "../../assets/img/emoji/9.gif";
import emoji10 from "../../assets/img/emoji/10.gif";
import emoji11 from "../../assets/img/emoji/11.gif";

const BubbleMessage = ({ message }) => {
  const getMessage = () => {
    switch (message) {
      case "emoji1":
        return <img src={emoji1} alt="mess" />;
      case "emoji2":
        return <img src={emoji2} alt="mess" />;
      case "emoji3":
        return <img src={emoji3} alt="mess" />;
      case "emoji4":
        return <img src={emoji4} alt="mess" />;
      case "emoji6":
        return <img src={emoji6} alt="mess" />;
      case "emoji7":
        return <img src={emoji7} alt="mess" />;
      case "emoji8":
        return <img src={emoji8} alt="mess" />;
      case "emoji9":
        return <img src={emoji9} alt="mess" />;
      case "emoji10":
        return <img src={emoji10} alt="mess" />;
      case "emoji11":
        return <img src={emoji11} alt="mess" />;
      default:
        return <span>{message}</span>;
    }
  };
  return (
    <div className="bubble-msg">
      <div className="triangle-isosceles left">{getMessage()}</div>
    </div>
  );
};

export default BubbleMessage;
