import { Toaster } from "react-hot-toast";
import Home from "./components/home/Home";
// import StartScreen from "./components/startScreen/startScreen";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    //  <StartScreen />
    <div className="wrapper">
      <Home />
      <div className="ludo-toast">
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            className: "custom-toast",
          }}
        />
      </div>
    </div>
  );
}

export default App;
